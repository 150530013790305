<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row dense>
              <v-col cols="12" v-if="abledforcast">
                <v-checkbox label="フォーキャスト指定" v-model="forecastOrderFlg"></v-checkbox>
              </v-col>
            </v-row>
            <v-col class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</v-col>
            <div style="height: 300px">
              <ag-grid-vue
                :alwaysShowHorizontalScroll="true"
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>
                {{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。 (一括取込番号：{{
                  importNumber
                }})
              </p>
              <p v-if="errorRows.length > 0">
                {{ isCafereoUser ? "受注" : "発注" }}情報の取込エラーがあります。確認してください。
              </p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      :title="isCafereoUser ? '受注一括取込' : '発注一括取込'"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import FileUtils from "../../utils/FileUtils";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { corpList as CorpList } from "../../consts/CorpList";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "OrderImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    file: null,
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
      },
      columnDefs: [],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
    },
    cafereoColumns: [
      { headerName: "行番号", field: "rowNumber" },
      { field: "customerCode", headerName: "社店CD" },
      { field: "janCode", headerName: "JANCODE" },
      { field: "orderQuantity", type: "numericColumn", headerName: "受注数" },
      { field: "deliveryCode", headerName: "配送CD" },
      { field: "directDeliveryRequestFlg", headerName: "直送依頼フラグ" },
      { field: "shipmentPlanDate", headerName: "出荷予定日" },
      // { field: "forecast", headerName: "フォーキャスト識別" },
      // { field: "remarks", headerName: "備考" },
      { field: "cafereoRemarks", headerName: "社内備考" },
      { field: "slipRemarks", headerName: "伝票備考" },
      { field: "partnerPurchaseNo", headerName: "取引先専用番号" },
      { field: "partnerDetailNo", headerName: "取引先専用明細番号" },
    ],
    vendorColumns: [
      { field: "janCode", headerName: "JANCODE" },
      { field: "orderQuantity", type: "numericColumn", headerName: "発注数" },
      { field: "partnerPurchaseNo", headerName: "取引先専用番号" },
      { field: "partnerDetailNo", headerName: "取引先専用明細番号" },
    ],
    csvRecords: [],
    importNumber: null,
    validImportForm: null,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      maxLength: Validation.maxLength,
      isRate: Validation.isRate,
      isMinNumber: Validation.isMinNumber,
      isCsv: Validation.isCsv,
      maxRateLength: Validation.maxRateLength,
    },
    importCount: 0,
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
      { headerName: "JANCODE", field: "jancode" },
    ],
    errorRows: [],
    abledforcast: false,
    forecastOrderFlg: false,
    loginUserCorporateCd: "",
    conversionCode: "",
  }),
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.loginUserCorporateCd = this.$store.getters["security/loggedInUser"].corporateCode;
          // 東急ハンズおよびヴィレッジヴァンガードはダブルコーテーションを取り除く
          var csvLines = [];
          if (
            CorpList.TOKYUHANDS == this.loginUserCorporateCd ||
            CorpList.VILLAGEVANGUARD == this.loginUserCorporateCd
          ) {
            csvLines = await FileUtils.readAsCsvSpecQuote(this.file, true);
          } else {
            csvLines = await FileUtils.readAsCsv(this.file, true);
          }
          this.csvRecords = [];
          this.conversionCode = "";
          csvLines.forEach((line, index) => {
            if (index !== 0 && line.length > 1) {
              if (this.isCafereoUser) {
                this.csvRecords.push({
                  rowNumber: index,
                  customerCode: line[0],
                  janCode: line[1],
                  orderQuantity: line[2],
                  deliveryCode: line[3],
                  directDeliveryRequestFlg: line[4],
                  shipmentPlanDate: line[5],
                  // forecast: "N", // 新フォーマットでは削除された項目
                  remarks: "", // 新フォーマットでは削除された項目
                  cafereoRemarks: line[6],
                  slipRemarks: line[7],
                  partnerPurchaseNo: line[8],
                  partnerDetailNo: line[9],
                });
              } else {
                var convertFormatData = this.convertFormatData(line);
                if (convertFormatData.isAdd) {
                  var csvRecord = convertFormatData.data;
                  csvRecord.rowNumber = this.csvRecords.length + 1;
                  this.csvRecords.push(csvRecord);
                }
              }
            }
          });
          if (this.isVendorUser) {
            this.abledforcast = this.$store.getters["security/loggedInUser"].allowForecast == "y" ? true : false;
            this.forecastOrderFlg = false;
          } else if (this.isCafereoUser) {
            this.abledforcast = true;
          } else {
            this.abledforcast = false;
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      for (let record of this.csvRecords) {
        var error = await this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            jancode: record.janCode,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      }
      if (isValid) {
        try {
          this.errorRows = [];
          this.loadingOn();
          var param = null;
          var response = null;
          if (this.isCafereoUser) {
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C020103", requestRecords.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }

            param = {
              forecast: this.forecastOrderFlg,
              orders: requestRecords,
            };
            response = await this.$store.dispatch("order/importBatch", {
              rows: [param],
              splitNum: 10000,
            });
          } else {
            param = {
              forecast: this.forecastOrderFlg,
              orders: requestRecords,
            };
            response = await this.$store.dispatch("order/import", param);
          }

          // 処理結果判定
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.importNumber = response.data.contents.importNumber;
              this.importCount = response.data.contents.orderCount;
              this.step++;
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              this.importNumber = response.data.contents.orderCount > 0 ? response.data.contents.importNumber : null;
              this.importCount = response.data.contents.orderCount;
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    rowNumber: key,
                    jancode: requestRecords[key - 1].janCode,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (this.errorRows.length > 0) {
                this.$refs.importErrorGrid.open({ records: this.errorRows });
              } else {
                this.$refs.importErrorGrid.close();
              }
              // 画面は完了画面に進める
              this.step++;
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    async validateRow(row) {
      var ret = true;
      var messages = [];
      if (this.isCafereoUser) {
        // 社店CD
        this.setValidMessage(this.rules.required(row.customerCode), "社店CD", messages);
        this.setValidMessage(this.rules.isNumber(row.customerCode), "社店CD", messages);
        this.setValidMessage(this.rules.maxLength(12)(row.customerCode), "社店CD", messages);

        // JANCODE
        this.setValidMessage(this.rules.required(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.isNumber(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.maxLength(13)(row.janCode), "JANCODE", messages);
        // 受注数
        this.setValidMessage(this.rules.required(row.orderQuantity), "受注数", messages);
        this.setValidMessage(this.rules.isNumber(row.orderQuantity), "受注数", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.orderQuantity), "受注数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.orderQuantity), "受注数", messages);
        // 配送CD
        this.setValidMessage(this.rules.required(row.deliveryCode), "配送CD", messages);
        this.setValidMessage(this.rules.maxLength(12)(row.deliveryCode), "配送CD", messages);
        // 直送依頼フラグ
        this.setValidMessage(this.isBoolean(row.directDeliveryRequestFlg), "直送依頼フラグ", messages);
        // 出荷予定日
        this.setValidMessage(this.isDate(row.shipmentPlanDate), "出荷予定日", messages);
        // フォーキャスト識別
        // this.setValidMessage(this.isBoolean(row.forecast), "フォーキャスト識別", messages);
        // 備考
        // this.setValidMessage(this.rules.maxLength(100)(row.remarks), "備考", messages);
        // 社内備考
        this.setValidMessage(this.rules.maxLength(100)(row.cafereoRemarks), "社内備考", messages);
        this.setValidMessage(this.rules.maxLength(30)(row.slipRemarks), "伝票備考", messages);
        // 取引先専用番号
        this.setValidMessage(this.rules.maxLength(64)(row.partnerPurchaseNo), "取引先専用番号", messages);
        // 取引先専用明細番号
        this.setValidMessage(this.rules.isNumber(row.partnerDetailNo), "取引先専用明細番号", messages);
        this.setValidMessage(this.rules.maxLength(6)(row.partnerDetailNo), "取引先専用明細番号", messages);
      } else {
        // JANCODE
        this.setValidMessage(this.rules.required(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.isNumber(row.janCode), "JANCODE", messages);
        this.setValidMessage(this.rules.maxLength(13)(row.janCode), "JANCODE", messages);

        // 受注数
        this.setValidMessage(this.rules.required(row.orderQuantity), "発注数", messages);
        this.setValidMessage(this.rules.isNumber(row.orderQuantity), "発注数", messages);
        this.setValidMessage(this.rules.isMinNumber(1)(row.orderQuantity), "発注数", messages);
        this.setValidMessage(this.rules.maxLength(8)(row.orderQuantity), "発注数", messages);

        // 取引先専用番号
        this.setValidMessage(this.rules.maxLength(64)(row.partnerPurchaseNo), "取引先専用番号", messages);
        // 取引先専用明細番号
        this.setValidMessage(this.rules.isNumber(row.partnerDetailNo), "取引先専用明細番号", messages);
        this.setValidMessage(this.rules.maxLength(6)(row.partnerDetailNo), "取引先専用明細番号", messages);
        // 変換CD
        this.setValidMessage(this.rules.maxLength(12)(row.conversionCode), "変換CD", messages);
      }

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      if (this.isCafereoUser) {
        return {
          customerCode: this.stringFormat(row.customerCode),
          janCode: this.stringFormat(row.janCode),
          unit: 0,
          orderQuantity: this.numberFormat(row.orderQuantity),
          deliveryCode: this.stringFormat(row.deliveryCode),
          directDeliveryRequestFlg: this.booleanFormat(row.directDeliveryRequestFlg),
          shipmentPlanDate: this.stringDateFormat(row.shipmentPlanDate),
          remarks: this.stringFormat(row.remarks),
          cafereoRemarks: this.stringFormat(row.cafereoRemarks),
          slipRemarks: this.stringFormat(row.slipRemarks),
          rowNumber: this.numberFormat(row.rowNumber),
          partnerPurchaseNo: this.stringFormat(row.partnerPurchaseNo),
          partnerDetailNo: this.numberFormat(row.partnerDetailNo),
        };
      } else {
        return {
          janCode: this.stringFormat(row.janCode),
          orderQuantity: this.numberFormat(row.orderQuantity),
          partnerPurchaseNo: this.stringFormat(row.partnerPurchaseNo),
          partnerDetailNo: this.numberFormat(row.partnerDetailNo),
          rowNumber: this.numberFormat(row.rowNumber),
          conversionCode: this.stringFormat(row.conversionCode),
        };
      }
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(value).format("YYYY-MM-DD");
    },
    booleanFormat(value) {
      if (value == null || value == "") return false;
      if (value == "Y") return true;
      if (value == "N") return false;
      return Boolean(value);
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isBoolean(value) {
      if (value == null) return true;
      if (value != "Y" && value != "N") return "YまたはNで入力してください";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    convertFormatData(line) {
      // ログインユーザが所属する法人によってマッピングを修正
      var ret = {
        isAdd: true,
        data: {
          rowNumber: null,
          janCode: "",
          orderQuantity: null,
          deliveryCode: "",
          partnerPurchaseNo: "",
          partnerDetailNo: null,
          conversionCode: "",
        },
      };
      if (CorpList.VILLAGEVANGUARD == this.loginUserCorporateCd) {
        ret.data.janCode = line[26];
        ret.data.orderQuantity = line[35];
        ret.data.conversionCode = line[9];
        ret.data.partnerPurchaseNo = line[23];
      } else if (CorpList.TOKYUHANDS == this.loginUserCorporateCd) {
        if (line[0] == 4) {
          // スキップ
          ret.isAdd = false;
        } else if (line[0] == 5) {
          // 変換CDを設定
          ret.isAdd = false;
          ret.data.partnerPurchaseNo = line[1];
          this.conversionCode = line[5];
        } else if (line[0] == 6) {
          // 行データ追加
          ret.data.janCode = line[5];
          ret.data.orderQuantity = Number(line[8]) / 10;
          ret.data.partnerPurchaseNo = line[1];
          ret.data.conversionCode = this.conversionCode;
        }
      } else {
        ret.data.janCode = line[0];
        ret.data.orderQuantity = line[1];
        ret.data.partnerPurchaseNo = line[2];
        ret.data.partnerDetailNo = line[3];
      }
      return ret;
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
  beforeMount() {
    if (this.isCafereoUser) this.gridOptions.columnDefs = this.cafereoColumns;
    if (this.isVendorUser) this.gridOptions.columnDefs = this.vendorColumns;
  },
};
</script>
