<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.order-locked:not(.ag-row-selected) {
  background-color: #d3d3d3;
}
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template v-if="isCafereoUser"
            ><v-icon>mdi-book-search</v-icon>{{ isAchievementMode ? " 出荷一覧" : "受注一覧" }}
          </template>
          <template v-else>
            <v-icon>mdi-note-search</v-icon>{{ isAchievementMode ? "入荷済み商品一覧" : "発注一覧" }}
          </template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-checkbox
                v-if="isCafereoUser"
                label="検索上限無し"
                v-model="searchModel.fetchLimitOverFlg"
                dense
              ></v-checkbox>
            </v-row>
            <v-row v-if="!isAchievementMode" dense>
              <v-select
                v-if="!isAchievementMode"
                clearable
                v-model="searchModel.orderStatus"
                :items="orderStatuses"
                :label="isCafereoUser ? '受注ステータス' : '発注ステータス'"
              ></v-select>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.orderDateFrom"
                  type="date"
                  label="発注日From"
                  :rules="[rules.orderedFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.orderDateTo"
                  type="date"
                  label="発注日To"
                  :rules="[rules.orderedTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser" dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.corporateName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="法人名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.customerName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="取引先名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="isCafereoUser || isVendorUser"
                  v-model="searchModel.jancode"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.productName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="商品名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser" dense>
              <v-col>
                <cafereo-users-field
                  v-if="isCafereoUser"
                  ref="salesAssistant"
                  label="担当AS"
                  v-model="searchModel.salesAssistantCd"
                  :attach="false"
                ></cafereo-users-field>
              </v-col>
            </v-row>
            <v-row v-if="isAchievementMode" dense>
              <v-col>
                <dp-date-picker
                  v-if="isAchievementMode"
                  v-model="searchModel.slipDateFrom"
                  type="date"
                  label="伝票日付From"
                  :rules="[rules.slipDateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-if="isAchievementMode"
                  v-model="searchModel.slipDateTo"
                  type="date"
                  label="伝票日付To"
                  :rules="[rules.slipDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser && isAchievementMode" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser && isAchievementMode"
                  v-model="searchModel.deliveryName1"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="届先法人名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser && isAchievementMode" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser && isAchievementMode"
                  v-model="searchModel.deliveryName2"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="届先名称"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser && isAchievementMode" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser && isAchievementMode"
                  v-model="searchModel.supplierName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="仕入先略称"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.salesTeam"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="担当チーム"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!isAchievementMode" dense>
              <v-col>
                <dp-date-picker
                  v-if="!isAchievementMode"
                  v-model="searchModel.releaseDateFrom"
                  type="date"
                  label="発売日From"
                  :rules="[rules.releaseDateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-if="!isAchievementMode"
                  v-model="searchModel.releaseDateTo"
                  type="date"
                  label="発売日To"
                  :rules="[rules.releaseDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser && !isAchievementMode" dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser && !isAchievementMode"
                  v-model="searchModel.supplierName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="メーカー名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCafereoUser" dense>
              <v-col>
                <cafereo-user-field
                  v-if="isCafereoUser"
                  ref="customerStaff"
                  label="担当者"
                  :value="defaultCustomerStaffr"
                  v-model="searchModel.customerStaff"
                  :attach="false"
                ></cafereo-user-field>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <template v-if="isVendorUser && !isAchievementMode">
          <tooltip-icon-menu-button
            text="承認・却下"
            :disabled="!enabledApprovalRejected"
            icon="mdi-check"
            v-if="allowedAction(['V020102', 'V020103'])"
          >
            <v-list dense>
              <v-list-item
                @click="onBtnApproval(true)"
                dense
                :disabled="!enabledApproval"
                v-if="allowedAction(['V020102'])"
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-check</v-icon></v-list-item-avatar>
                <v-list-item-title>承認</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="onBtnApproval(false)"
                dense
                :disabled="!enabledrejected"
                v-if="allowedAction(['V020103'])"
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-close</v-icon></v-list-item-avatar>
                <v-list-item-title>却下</v-list-item-title>
              </v-list-item>
            </v-list>
          </tooltip-icon-menu-button>
          <confirm-grid-dialog ref="confirmGrid" width="1100px" height="350px" :autoClose="false"></confirm-grid-dialog>
          <v-divider v-if="isVendorUser" vertical></v-divider>
        </template>
        <template v-if="isCafereoUser && !isAchievementMode">
          <tooltip-icon-button
            v-if="allowedAction(['C020102'])"
            icon="mdi-pencil-plus-outline"
            @click="onBtnInsert"
            :disabled="!enabledInsert"
            >新規登録</tooltip-icon-button
          >
        </template>
        <template v-if="!isAchievementMode && allowedAction(['C020103', 'V020104'])">
          <tooltip-icon-button icon="mdi-database-import" @click="onBtnImport" :disabled="!enabledImport"
            >新規一括取り込み</tooltip-icon-button
          >
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <order-import
              @complete="onImportComplete"
              @expand="importDialog.width = $event ? '100%' : '800px'"
              v-if="importDialog.show"
            ></order-import>
          </v-dialog>
          <v-divider vertical></v-divider>
        </template>
        <template v-if="isCafereoUser || (!isAchievementMode && isVendorUser)">
          <tooltip-icon-button
            v-if="allowedAction(['C020104', 'V020105'])"
            :disabled="!enabledEdit"
            icon="mdi-pencil-outline"
            @click="onBtnUpdate"
            >編集</tooltip-icon-button
          >
          <v-dialog v-model="editDialog" max-width="1800px" persistent scrollable>
            <order-entry
              v-if="editDialog"
              :inputModel="orderModel"
              @cancel="editDialog = false"
              @created="onCreated"
              @updated="onUpdated"
            ></order-entry>
          </v-dialog>
        </template>
        <template>
          <tooltip-icon-button
            v-if="!isAchievementMode && (allowedAction(['C020110']) || allowedAction(['V020108']))"
            :disabled="!enabledOrderBulkEdit"
            icon="mdi-database-edit"
            @click="onBtnOrderBulkEdit"
            >{{ isCafereoUser ? "受注一括編集" : "発注一括編集" }}</tooltip-icon-button
          >
          <v-dialog v-model="orderBulkEditDialog" max-width="1800px" persistent scrollable>
            <order-bulk-edit
              v-if="orderBulkEditDialog"
              :inputModel="selectionRows"
              @onDialogClose="onBulkEditDialogClose"
              @onEditSubmit="onBulkEditSubmit"
            ></order-bulk-edit>
          </v-dialog>
        </template>
        <template v-if="!isAchievementMode">
          <tooltip-icon-button
            v-if="allowedAction(['C020105', 'V020106'])"
            :disabled="!enabledCancel"
            icon="mdi-pencil-remove-outline"
            @click="onBtnCancel"
            >キャンセル</tooltip-icon-button
          >
        </template>
        <tooltip-icon-button
          v-if="
            isCafereoUser &&
            ((!isAchievementMode && allowedAction(['C020106'])) || (isAchievementMode && allowedAction(['C020203'])))
          "
          :disabled="!enabledBackOrderEntry"
          icon="mdi-view-split-vertical"
          @click="onBtnBackOrderEntry"
          >注残分割</tooltip-icon-button
        >
        <v-dialog v-model="backOrderDialog" max-width="1000px" persistent scrollable>
          <back-order-entry
            :orderModel="backOrderModel"
            @cancel="backOrderDialog = false"
            @created="onBackOrderCreated"
            v-if="backOrderDialog"
          ></back-order-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="isCafereoUser && !isAchievementMode && allowedAction(['C020107'])"
          :disabled="!enabledDivide"
          icon="mdi-set-split"
          @click="onBtnDivide"
          >分割</tooltip-icon-button
        >
        <v-dialog v-model="divideDialog" max-width="1000px" persistent scrollable>
          <order-split
            :orderModel="selectedRow"
            @cancel="divideDialog = false"
            @created="onDivideSubmit"
            v-if="divideDialog"
          ></order-split>
        </v-dialog>
        <tooltip-icon-button
          v-if="isCafereoUser && isAchievementMode && allowedAction(['C020204'])"
          :disabled="!enabledReturn"
          icon="mdi-sync"
          @click="onBtnReturnEntry"
          >返品</tooltip-icon-button
        >
        <tooltip-icon-button
          icon="mdi-calendar-star"
          v-if="isCafereoUser && !isAchievementMode && allowedAction(['C020108'])"
          @click="onHyconClick"
          :disabled="!enabledHycon"
          >ハイコン設定</tooltip-icon-button
        >
        <datepicker-confirm-grid-dialog
          ref="hyconConfirmGrid"
          width="1100px"
          height="350px"
          :autoClose="false"
          :abountDate="false"
        ></datepicker-confirm-grid-dialog>
        <tooltip-icon-button
          v-if="isAchievementMode && allowedAction(['C020205', 'V020202'])"
          icon="mdi-book-arrow-down"
          @click="onDeliveryReportPrint"
          :disabled="!enabledeliveryReportPrint"
          >納品書出力</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="isCafereoUser && isAchievementMode && allowedAction(['C020207'])"
          :disabled="!enabledUpadateSlipDate"
          icon="mdi-update"
          @click="onBtnUpadateSlipDate"
          >伝票日付変更</tooltip-icon-button
        >
        <v-dialog v-model="upadateSlipDateDialog" max-width="1000px" persistent scrollable>
          <update-slip-date
            :inputModel="selectionRows"
            @update="onUpadateSlipDateUpdated"
            v-if="upadateSlipDateDialog"
          ></update-slip-date>
        </v-dialog>
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" :disabeld="!enabledCsvExport"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>
    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-row v-if="isCafereoUser">
          <v-col cols="2">
            <v-card v-if="!isAchievementMode" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                受注数合計：{{ this.orderQuantityTotal | comma }}
              </v-card-text>
            </v-card>
            <v-card v-if="isAchievementMode" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                出荷数合計：{{ this.shipQuantityTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                {{ isAchievementMode ? "出荷" : "受注" }}合計金額：{{ this.orderPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="2">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                税込{{ isAchievementMode ? "出荷" : "受注" }}合計金額：{{ this.orderPriceTaxTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col cols="2">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                粗利合計金額：{{ this.grossPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4"> 粗利率：{{ this.grossRate.toFixed(1) }}% </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer
        ></v-row>
        <v-row v-if="isVendorUser">
          <v-col cols="3">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                {{ isAchievementMode ? "入荷金額合計" : "発注合計金額" }}：{{ this.orderPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                {{ isAchievementMode ? "税込入荷金額合計" : "税込発注合計金額" }}：{{ this.orderPriceTaxTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                {{ isAchievementMode ? "[選択]入荷金額合計" : "[選択]発注合計金額" }}：{{
                  this.selectedOrderPriceTotal | comma
                }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          id="OrderList"
          style="height: 98%"
          class="ag-theme-alpine"
          :gridOptions="gridOptions"
          :rowData="rowData"
          @selection-changed="onSelectionChanged"
          @filter-changed="calcTotalItem"
        ></ag-grid-vue>
      </v-col>
      <v-col v-if="detailSelected" id="OrderInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <order-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :orderId="selectedRow != null ? selectedRow.orderId : null"
          :isAchievementMode="isAchievementMode"
        ></order-infos>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="orderReturnEntry"
      width="80%"
      height="80%"
      icon="mdi-calendar-check"
      title="販売店返品登録"
      btnSubmit="登録"
      :autoClose="false"
      :columns="orderReturnEntryColmuns"
    ></confirm-grid-dialog>
    <error-grid-dialog
      ref="bulkErrorGrid"
      width="80%"
      height="80%"
      title="承認"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { ConstantLoader } from "../../mixins/loader";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  NumericDP2Column,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { LockedCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import OrderTypes from "../../consts/OrderTypes";
import CafereoOrderStatuses from "../../consts/orderStatus/CafereoOrderStatuses";
import VendorOrderStatuses from "../../consts/orderStatus/VendorOrderStatuses";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import {
  Cafereo as CafereoColumnDefs,
  CafereoDetail as CafereoDetailColumnDefs,
  Vendor as VendorColumnDefs,
  VendorDetail as VendorDetailColumnDefs,
  CafereoShipped as CafereoShippedColumnDefs,
  VendorShipped as VendorShippedColumnDefs,
  VendorShipDetail as VendorShipDetailColumnDefs,
} from "../../consts/columns/OrderColumns";
import OrderInfos from "./../../components/order/OrderInfos.vue";
import OrderEntry from "./../../components/order/OrderEntry.vue";
import OrderSplit from "../../components/order/OrderSplit.vue";
import BackOrderEntry from "../../components/order/BackOrderEntry.vue";
import UpdateSlipDate from "../../components/order/UpdateSlipDate.vue";
import OrderBulkEdit from "../../components/order/OrderBulkEdit.vue";
import OrderImport from "../../components/order/OrderImport.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconMenuButton from "../../components/common/TooltipIconMenuButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import CafereoUsersField from "../../components/common/fields/CafereoUsersField.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";
import { comma } from "../../filter/NumberFilter";
import productConditionTypes from "../../consts/productConditionTypes";
import { CustomDateCellEditor } from "../../components/ag-grid/cellEditors";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import DatepickerConfirmGridDialog from "../../components/common/DatepickerConfirmGridDialog.vue";
import { AutocompleteSelectCellEditor } from "ag-grid-autocomplete-editor";

export default {
  name: "OrderSearch",
  mixins: [ConstantLoader({ CafereoOrderStatuses })],
  components: {
    AgGridVue,
    OrderInfos,
    OrderEntry,
    OrderSplit,
    BackOrderEntry,
    UpdateSlipDate,
    OrderBulkEdit,
    OrderImport,
    SearchConditions,
    TooltipIconButton,
    TooltipIconMenuButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    CafereoUserField,
    CafereoUsersField,
    ErrorGridDialog,
    DatepickerConfirmGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
  },
  data() {
    return {
      infoMaximum: false,
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpNumericDP2Column: NumericDP2Column,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
          dpCellDatePicker: CustomDateCellEditor,
        },
        defaultColDef: {
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            cellRenderer: LockedCellRenderer({ matcher: "orderLocked" }),
          },
        ],
        defaultCsvExportParams: {
          allColumns: true,
          onlySelectedAllPages: false,
        },
        suppressCsvExport: false,
        suppressExcelExport: true,
        suppressColumnVirtualisation: true,
        alwaysShowHorizontalScroll: true,
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        // rowClassRules: {
        //   "order-locked": (params) => Boolean(params.data.orderLocked),
        // },
        getRowNodeId: (data) => data.orderId,
      },
      gridStyle: { height: "95%", "flex-basis": "auto" },
      rowData: [],
      selectionRows: [],
      searchModel: {
        fetchLimitOverFlg: false,
        orderStatus: null,
        orderDateFrom: null,
        orderDateTo: null,
        customerName: null,
        corporateName: null,
        jancode: null,
        productName: null,
        salesAssistantCd: null,
        deliveryName1: null,
        slipDateFrom: null,
        slipDateTo: null,
        deliveryName2: null,
        maker: null,
        salesTeam: null,
        releaseDateFrom: null,
        releaseDateTo: null,
        supplierName: null,
        customerStaff: null,
      },
      //
      detailSelected: false,
      orderModel: {
        createNew: false,
      },
      orderBulkEditModel: {},
      editDialog: false,
      orderBulkEditDialog: false,
      divideDialog: false,
      backOrderDialog: false,
      upadateSlipDateDialog: false,
      backOrderModel: {},
      upadateSlipDateModel: {},
      importDialog: { show: false, width: "800px" },
      returnDialog: false,
      orderReturnModel: {
        createNew: false,
      },
      orderQuantityTotal: 0,
      orderPriceTotal: 0,
      orderPriceTaxTotal: 0,
      selectedOrderPriceTotal: 0,
      lowerPriceTotal: 0,
      grossPriceTotal: 0,
      grossRate: 0,
      deliverys: [],
      cafereoColumns: [...CafereoColumnDefs],
      cafereoShipColumns: [...CafereoShippedColumnDefs],
      vendorColumns: [...VendorColumnDefs],
      vendorShipColumns: [...VendorShippedColumnDefs],
      orderReturnEntryColmuns: [
        { headerName: "受注ID", field: "orderId", pinned: "left" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: { backgroundColor: "#FFEDB3" },
        },
        {
          headerName: "商品状態区分",
          field: "productConditionType",
          editable: true,
          cellStyle: { backgroundColor: "#FFEDB3" },
          cellEditor: "agSelectCellEditor",
          cellEditorParams: (params) => {
            if (params.data.orderType == OrderTypes.ENTRUST || params.data.orderType == OrderTypes.DIGESTIVE) {
              return {
                values: [productConditionTypes.of(productConditionTypes.BAD)],
              };
            }
            return {
              values: productConditionTypes.all(false).map((conditionType) => {
                return conditionType.text;
              }),
            };
          },
        },
        {
          headerName: "メーカー直送",
          field: "directDeliveryFlg",
          filter: "dpBooleanFilter",
          filterParams: {
            caption: { true: "YES", false: "NO" },
          },
          editable: true,
          cellEditor: "dpCheckboxCellEditor",
          cellRenderer: "dpCheckboxCellRenderer",
          cellStyle: { backgroundColor: "#FFEDB3" },
        },
        {
          field: "supplierDeliveryCode",
          headerName: "配送先",
          width: 265,
          cellEditor: AutocompleteSelectCellEditor,
          cellStyle: { backgroundColor: "#FFEDB3" },
          editable: (params) => {
            return params.data.directDeliveryFlg;
          },
          cellEditorParams: (params) => {
            return {
              selectData: this.deliverys.find((delivery) => delivery.orderId == params.data.orderId).deliverys,
              placeholder: "配送先入力",
              required: true,
              autocomplete: {
                showOnFocus: true,
              },
            };
          },
          valueFormatter: (params) => {
            if (params.value) {
              return params.value.label || params.value.value || params.value;
            }
            return "";
          },
        },
      ],
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        orderedFrom: (value) => this.orderedFromRules(value),
        orderedTo: (value) => this.orderedToRules(value),
        slipDateFrom: (value) => this.slipDateFromRules(value),
        slipDateTo: (value) => this.slipDateToRules(value),
        releaseDateFrom: (value) => this.releaseDateFromRules(value),
        releaseDateTo: (value) => this.releaseDateToRules(value),
      },
      validSeearchForm: null,
      defaultCustomerStaffr: null,
      shipQuantityTotal: 0,
      cafereoDetailColumns: [...CafereoDetailColumnDefs],
      errorColmuns: null,
      cafereoErrorColmuns: [
        { headerName: "受注ID", field: "orderId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      returnErrorColmuns: [
        { headerName: "行", field: "rowNo" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      vendorErrorColmuns: [
        { headerName: "発注ID", field: "orderId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      vendorDetailColumns: [...VendorDetailColumnDefs],
      vendorApprovalColumns: [
        { headerName: "発注ID", field: "orderId", pinned: "left" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
        {
          headerName: "発注ステータス",
          field: "orderStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorOrderStatuses.all() },
          valueGetter: (params) => VendorOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorProductStatus.all() },
          valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "発注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "発売元", field: "supplierName" },
        { headerName: "販売元", field: "makerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
        },
        { headerName: "発注数", field: "orderQuantity", type: "dpNumericColumn" },
        {
          headerName: "卸単価",
          field: "unitPrice",
          type: "dpNumericDP2Column",
        },
        { headerName: "卸単価金額", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
        { headerName: "案件番号", field: "propositionNumber" },
      ],
      vendorShipDetailColumns: [...VendorShipDetailColumnDefs],
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    if (this.isAchievementMode) {
      if (this.isCafereoUser) this.gridOptions.columnDefs.push(...this.cafereoShipColumns);
      if (this.isVendorUser) this.gridOptions.columnDefs.push(...this.vendorShipColumns);
    } else {
      if (this.isCafereoUser) this.gridOptions.columnDefs.push(...this.cafereoColumns);
      if (this.isVendorUser) this.gridOptions.columnDefs.push(...this.vendorColumns);
    }
    if (this.isCafereoUser) this.errorColmuns = this.cafereoErrorColmuns;
    if (this.isVendorUser) this.errorColmuns = this.vendorErrorColmuns;
    this.defaultCustomerStaffr = this.$store.getters["security/loggedInUser"].loginId;
  },
  mounted() {
    this.setAmazonColumnVisible();
    if (this.isVendorUser) {
      this.searchModel.orderDateFrom = moment().add(-1, "months").format("YYYY-MM-DD");
      this.onSearchClick();
    }
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  filters: { comma },
  watch: {
    $route() {
      this.onSearchClick();
      this.handleResize();
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.handleResize();
    },
    selectionRows(rows) {
      this.cancelFlg = rows.length > 0;
      rows.forEach((row) => {
        if (row.orderStatus >= 4) this.cancelFlg = false;
      });
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  computed: {
    isAchievementMode() {
      return this.$route.name === "OrderAchievementSearch";
    },
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    rowsSelected() {
      return this.selectionRows.length > 0;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      var detailColumns = null;
      if (this.isCafereoUser) {
        detailColumns = this.isAchievementMode ? this.cafereoShipColumns : this.cafereoDetailColumns;
      } else {
        detailColumns = this.isAchievementMode ? this.vendorShipDetailColumns : this.vendorDetailColumns;
      }
      return getDisplayDetails2(this.selectedRow.orderId, detailColumns, this.gridOptions.api);
    },
    orderStatuses() {
      if (this.isCafereoUser) {
        var cafereoOrderStatuses = CafereoOrderStatuses.all().filter(function (r) {
          return r.value != CafereoOrderStatuses.SHIPPED;
        });
        return cafereoOrderStatuses;
      } else {
        var vendorOrderStatuses = VendorOrderStatuses.all().filter(function (r) {
          return r.value != VendorOrderStatuses.SHIPPED;
        });
        return vendorOrderStatuses;
      }
    },
    enabledInsert() {
      return this.allowedAction(["C020102"]);
    },
    enabledImport() {
      if (this.isCafereoUser) {
        return this.allowedAction(["C020103"]);
      } else {
        return this.allowedAction(["V020104"]);
      }
    },
    enabledApprovalRejected() {
      return this.selectionRows.length > 0 && this.selectionRows.every((r) => r.orderStatus == 1 && !r.orderLocked);
    },
    enabledApproval() {
      return this.allowedAction(["V020102"]);
    },
    enabledrejected() {
      return this.allowedAction(["V020103"]);
    },
    enabledEdit() {
      if (this.isCafereoUser) {
        return (
          this.allowedAction(["C020104"]) &&
          this.selectedRow &&
          !this.selectedRow.orderLocked &&
          this.selectedRow.orderType == 1
        );
      } else {
        return (
          this.allowedAction(["V020105"]) &&
          this.selectedRow &&
          !this.selectedRow.orderLocked &&
          this.selectedRow.orderStatus <= 3 &&
          this.selectedRow.orderType == 1
        );
      }
    },
    enabledOrderBulkEdit() {
      if (this.isCafereoUser) {
        return (
          this.allowedAction(["C020104"]) &&
          this.selectionRows.length > 0 &&
          this.selectionRows.every((r) => !r.orderLocked && r.orderType == 1)
        );
      } else {
        return (
          this.allowedAction(["V020105"]) &&
          this.selectionRows.length > 0 &&
          this.selectionRows.every((r) => r.orderStatus <= 3 && !r.orderLocked && r.orderType == 1)
        );
      }
    },
    enabledCancel() {
      if (this.isCafereoUser) {
        return (
          this.allowedAction(["C020105"]) &&
          this.rowsSelected &&
          this.selectionRows.every((r) => r.orderStatus <= 4 && !r.orderLocked)
        );
      } else {
        return (
          this.allowedAction(["V020106"]) &&
          this.rowsSelected &&
          this.selectionRows.every((r) => r.orderStatus <= 3 && !r.orderLocked)
        );
      }
    },
    enabledBackOrderEntry() {
      if (!this.isAchievementMode) {
        return (
          this.allowedAction(["C020106"]) &&
          this.selectedRow &&
          !this.selectedRow.orderLocked &&
          this.selectedRow.backOrderQuantity > 0 &&
          !this.selectedRow.forecastOrderFlg
        );
      } else {
        return (
          this.allowedAction(["C020106"]) &&
          this.selectedRow &&
          !this.selectedRow.orderLocked &&
          this.selectedRow.backOrderQuantity > 0
        );
      }
    },
    enabledDivide() {
      return (
        this.allowedAction(["C020107"]) &&
        this.selectedRow &&
        !this.selectedRow.orderLocked &&
        !this.selectedRow.parentOrderId &&
        !this.selectedRow.forecastOrderFlg &&
        !this.selectionRows.every((r) => r.orderType != OrderTypes.PURCHASE && r.wholesaleRate == 0)
      );
    },
    enabledHycon() {
      return (
        this.allowedAction(["C020108"]) &&
        this.selectionRows.length > 0 &&
        !this.selectionRows.some((r) => r.orderLocked)
      );
    },
    isAmazonStaff() {
      let special = this.$store.getters["security/loggedInUser"].special;
      return special.includes(1);
    },
    enabledReturn() {
      return (
        this.allowedAction(["C020204"]) &&
        this.selectionRows.length > 0 &&
        this.selectionRows.every((r) => r.orderStatus == CafereoOrderStatuses.SHIPPED && !r.orderLocked)
      );
    },
    enabledeliveryReportPrint() {
      if (this.isCafereoUser) {
        return this.allowedAction(["C020205"]) && this.selectionRows.length > 0;
      } else {
        return this.allowedAction(["V020202"]) && this.selectionRows.length > 0;
      }
    },
    enabledUpadateSlipDate() {
      return this.selectionRows.length > 0;
    },
    enabledCsvExport() {
      if (this.isCafereoUser) {
        let action = this.isAchievementMode ? "C020206" : "C020109";
        return this.allowedAction(action);
      } else {
        let action = this.isAchievementMode ? "V020203" : "V020107";
        return this.allowedAction([action]);
      }
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        var params = { ...this.searchModel };
        // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
        params.jancode = this.searchModel.jancode
          ? this.searchModel.jancode
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        var searchUrl = this.isAchievementMode ? "order/ordersshippedSearch" : "order/search";
        if (!this.isCafereoUser) {
          delete params.customerStaff;
        }
        const response = await this.$store.dispatch(searchUrl, params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "";
          if (this.isCafereoUser) {
            title = this.isAchievementMode ? "出荷一覧" : "受注一覧";
          } else {
            title = this.isAchievementMode ? "入荷済み商品一覧" : "発注一覧";
          }
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.orders).length === 0) {
          let title = "";
          if (this.isCafereoUser) {
            title = this.isAchievementMode ? "出荷一覧" : "受注一覧";
          } else {
            title = this.isAchievementMode ? "入荷済み商品一覧" : "発注一覧";
          }
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.orders);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("OrderSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnExport() {
      var fileName = "";
      if (this.isCafereoUser) {
        fileName = this.isAchievementMode ? "出荷" : "受注";
      } else {
        fileName = this.isAchievementMode ? "入荷済み商品" : "発注";
      }
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      var params = {
        fileName: `${fileName}一覧.csv`,
        allColumns: false,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      };
      this.gridOptions.api.exportDataAsCsv(params);
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      this.gridOptions.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
      this.selectedOrderPriceTotal = 0;
      for (let row of this.selectionRows) this.selectedOrderPriceTotal += row.unitPriceTotal ? row.unitPriceTotal : 0;
    },
    onBtnInsert() {
      this.orderModel = {
        createNew: true,
        orderType: OrderTypes.PURCHASE,
      };
      this.editDialog = true;
    },
    onBtnUpdate() {
      this.orderModel = {
        createNew: false,
        orderRecord: this.selectedRow,
      };
      this.editDialog = true;
    },
    onCreated() {
      this.editDialog = false;
    },
    onBtnOrderBulkEdit() {
      this.orderBulkEditModel = { ...this.selectedRow };
      this.orderBulkEditDialog = true;
    },
    onBulkEditDialogClose(rows) {
      this.orderBulkEditDialog = false;
      if (rows.length > 0) this.onBulkEditSubmit(rows);
    },
    onBulkEditSubmit(rows) {
      rows.forEach((row) => {
        // 修正対象行を取得
        var targetRow = this.selectionRows.find((r) => {
          return r.orderId == row.orderId;
        });
        if (targetRow) {
          // 更新値への変更
          this.updateEditRow(targetRow, row);
        }
      });
      this.calcTotalItem();
      // 連続で一括編集するときに値が変わってない対応
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      this.orderBulkEditDialog = false;
    },
    /** 受注編集後の値を対象行の各項目へ反映する */
    updateEditRow(targetRow, updatedRow) {
      targetRow.cafereoRemarks = updatedRow.cafereoRemarks;
      targetRow.deliveryAddress = updatedRow.deliveryAddress;
      targetRow.deliveryCode = updatedRow.deliveryCode;
      targetRow.deliveryName1 = updatedRow.deliveryName1;
      targetRow.deliveryName2 = updatedRow.deliveryName2;
      targetRow.directDeliveryRequestFlg = updatedRow.directDeliveryRequestFlg;
      targetRow.grossProfit = updatedRow.grossProfit;
      targetRow.grossProfitTotal = updatedRow.grossProfitTotal;
      targetRow.orderQuantity = updatedRow.orderQuantity;
      targetRow.remarks = updatedRow.remarks;
      targetRow.directDeliveryRequestFlg = updatedRow.directDeliveryRequestFlg;
      targetRow.shipmentPlanDate = updatedRow.shipmentPlanDate;
      targetRow.unitPrice = updatedRow.unitPrice;
      targetRow.unitPriceTotal = updatedRow.unitPriceTotal;
      targetRow.updateUser = updatedRow.updateUser;
      targetRow.updateDatetime = updatedRow.updateDatetime;
      targetRow.wholesaleRate = updatedRow.wholesaleRate;
      targetRow.partnerPurchaseNo = updatedRow.partnerPurchaseNo;
      targetRow.partnerDetailNo = updatedRow.partnerDetailNo;
      targetRow.slipRemarks = updatedRow.slipRemarks;
      this.gridOptions.api.applyTransaction({ update: [targetRow] });
    },
    onUpdated(orderRecord) {
      this.editDialog = false;
      this.selectedRow.cafereoRemarks = orderRecord.cafereoRemarks;
      this.selectedRow.deliveryAddress = orderRecord.deliveryAddress;
      this.selectedRow.deliveryCode = orderRecord.deliveryCode;
      this.selectedRow.deliveryName1 = orderRecord.deliveryName1;
      this.selectedRow.deliveryName2 = orderRecord.deliveryName2;
      this.selectedRow.directDeliveryRequestFlg = orderRecord.directDeliveryRequestFlg;
      this.selectedRow.grossProfit = orderRecord.grossProfit;
      this.selectedRow.grossProfitTotal = orderRecord.grossProfitTotal;
      this.selectedRow.orderQuantity = orderRecord.orderQuantity;
      this.selectedRow.remarks = orderRecord.remarks;
      this.selectedRow.directDeliveryRequestFlg = orderRecord.directDeliveryRequestFlg;
      this.selectedRow.shipmentPlanDate = orderRecord.shipmentPlanDate;
      this.selectedRow.unitPrice = orderRecord.unitPrice;
      this.selectedRow.unitPriceTotal = orderRecord.unitPriceTotal;
      this.selectedRow.updateUser = orderRecord.updateUser;
      this.selectedRow.updateDatetime = orderRecord.updateDatetime;
      this.selectedRow.wholesaleRate = orderRecord.wholesaleRate;
      this.selectedRow.partnerPurchaseNo = orderRecord.partnerPurchaseNo;
      this.selectedRow.partnerDetailNo = orderRecord.partnerDetailNo;
      this.selectedRow.slipRemarks = orderRecord.slipRemarks;
      this.selectedRow.forecastOrderFlg = orderRecord.forecastOrderFlg;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.calcTotalItem();
    },
    onBtnBackOrderEntry() {
      this.backOrderModel = { ...this.selectedRow };
      this.backOrderDialog = true;
    },
    async onBackOrderCreated() {
      this.backOrderDialog = false;
      this.onSearchClick();
    },
    onBtnUpadateSlipDate() {
      this.upadateSlipDateModel = { ...this.selectedRow };
      this.upadateSlipDateDialog = true;
    },
    async onUpadateSlipDateUpdated(searchFlg) {
      this.upadateSlipDateDialog = false;
      if (searchFlg) this.onSearchClick();
    },
    async onBtnCancel() {
      const displayName = this.isCafereoUser ? "受注" : "発注";
      let messageText = `選択された${displayName}をキャンセルします <small>(${this.selectionRows.length}件)</small>`;
      if (this.selectionRows.every((r) => r.assignedForecastFlg)) {
        messageText += `<br><small>※フォーキャスト分のため<br>${displayName}数量は親${displayName} に統合されます</small>`;
      }
      const ok = await this.$dialog.confirm({ title: `${displayName}一覧`, text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          const cancelOrders = {
            cancelOrders: this.selectionRows.map((r) => ({
              orderId: r.orderId,
              lastUpdateDatetime: r.updateDatetime,
            })),
          };
          const response = await this.$store.dispatch("order/cancel", cancelOrders);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`${displayName}をキャンセルしました`, { timeout: 2300 });
              this.onSearchClick();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              this.loadingOff();
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (response.data.header.messages[r.orderId]) {
                  tmp = {
                    orderId: r.orderId,
                    errorMsg: response.data.header.messages[r.orderId]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: `${displayName}ID`,
                    field: "orderId",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderSearch::onBtnCancel", error);
          this.apiRequestError(error);
        }
      }
    },
    onBtnDivide() {
      this.divideDialog = true;
    },
    async onDivideSubmit() {
      this.divideDialog = false;
      this.onSearchClick();
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete() {
      this.importDialog.show = false;
    },
    async onBtnReturnEntry() {
      this.loadingOn();
      let selectionRows = JSON.parse(JSON.stringify(this.gridOptions.api.getSelectedRows()));
      this.deliverys = [];
      for (let i = 0; i < selectionRows.length; i++) {
        try {
          let rowDeliverys = [];
          let foundDelivery = this.deliverys.find((elm) => {
            return elm.supplierCd === selectionRows[i].supplierCd;
          });
          if (foundDelivery) {
            // 前と同じ仕入先がある場合はAPIコール不要（パフォーマンス対応）
            this.deliverys.push({
              orderId: selectionRows[i].orderId,
              deliverys: foundDelivery.deliverys,
              supplierCd: selectionRows[i].supplierCd,
            });
            if (foundDelivery.deliverys.length > 0) selectionRows[i].supplierDeliveryCode = foundDelivery.deliverys[0];
          } else {
            const response = await this.$store.dispatch("deliveryDestination/searchDelivery", {
              groupCode: selectionRows[i].supplierCd,
            });
            if (!ApiStatus.isSystemError(response.data?.header)) {
              var deliverys = response.data.contents.deliveries;
              for (let i = 0; i < deliverys.length; i++) {
                const delivery = deliverys[i];
                const deliveryInfo = {
                  value: delivery.deliveryCode,
                  label: delivery.delivery2,
                };
                rowDeliverys.push(deliveryInfo);
              }
              this.deliverys.push({
                orderId: selectionRows[i].orderId,
                deliverys: rowDeliverys,
                supplierCd: selectionRows[i].supplierCd,
              });
              if (rowDeliverys.length > 0) selectionRows[i].supplierDeliveryCode = rowDeliverys[0];
            }
          }
        } catch (error) {
          console.error("OrderSearch::onBtnReturnEntry", error);
          this.apiRequestError(error);
        }
      }
      this.loadingOff();
      this.$refs.orderReturnEntry.open({
        records: selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            let errorRows = [];
            let isValid = true;
            records.forEach((record) => {
              var error = this.onValidOrderReturn(record);
              if (error != true) {
                errorRows.push({
                  orderId: record.orderId,
                  errorMessage: error,
                });
                isValid = false;
              }
              updateModels.push({
                orderDate: record.orderDate,
                productCode: record.productCode,
                returnQuantity: Number(record.returnQuantity),
                productConditionType: Number(productConditionTypes.textOf(record.productConditionType)),
                directDeliveryFlg: record.directDeliveryFlg ? record.directDeliveryFlg : false,
                orderId: record.orderId,
                orderType: record.orderType,
                deliveryCode: record.supplierDeliveryCode?.value,
              });
            });
            var requestContents = {
              orderReturns: updateModels,
            };
            if (isValid) {
              const result = await this.$store.dispatch("orderReturn/entry", requestContents);

              let error = result.data?.header;
              const removeRow = [];

              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        rowNo: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  records.forEach((row, idx) => {
                    errorRows.forEach((error) => {
                      if (idx != Number(error.rowNo) - 1) {
                        removeRow.push(row);
                      }
                    });
                  });
                  if (errorRows.length > 0) {
                    if (removeRow.length > 0)
                      this.$refs.orderReturnEntry.gridOptions.api.applyTransaction({ remove: removeRow });
                    this.$refs.bulkErrorGrid.open({
                      title: "販売店返品登録",
                      records: errorRows,
                      columns: this.returnErrorColmuns,
                    });
                    this.$dialog.notify.error(`販売店返品登録に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`販売店返品を登録しました`, { timeout: 2300 });
                    this.$refs.orderReturnEntry.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } else {
              this.$refs.bulkErrorGrid.open({ title: "販売店返品登録", records: errorRows });
              this.$dialog.notify.error(`入力エラーが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            }
          } catch (error) {
            console.error("OrderSearch::onBtnApproval::onSubmit", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    returnQuantityRule(value, purchaseQuantity) {
      if (value == null || !value.match(/^([1-9]\d*|0)$/)) return true;
      if (Number(value) > purchaseQuantity) return "返品数は受注数以下の数値を指定してください";
      return true;
    },
    onValidOrderReturn(row) {
      var ret = true;
      var messages = [];

      // 返品数
      this.setValidMessage(this.rules.required(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isNumber(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.returnQuantityRule(row.returnQuantity, row.orderQuantity), "返品数", messages);
      // 商品状態
      this.setValidMessage(this.rules.required(row.productConditionType), "商品状態区分", messages);

      // 配送先
      if (row.directDeliveryFlg)
        this.setValidMessage(this.rules.required(row.supplierDeliveryCode?.value), "配送先", messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    onBtnApproval(approval) {
      this.$refs.confirmGrid.open({
        title: approval ? "承認" : "却下",
        columns: this.vendorApprovalColumns,
        records: this.selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            records.forEach((row) => {
              updateModels.push({
                orderId: row.orderId,
                lastUpdateDatetime: row.updateDatetime,
              });
            });
            const updateModel = {
              orders: updateModels,
            };
            const url = approval ? "order/approval" : "order/rejected";
            const result = await this.$store.dispatch(url, updateModel);
            let error = result.data?.header;
            // 一覧更新レコード
            let updateRows = [];
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      orderId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 一覧部の更新分のレコード変更
                if (result.data.contents) {
                  this.selectionRows.forEach((row) => {
                    result.data.contents.orders.forEach((order) => {
                      let isError = errorRows.some((r) => r.orderId === row.orderId);
                      if (order.orderId == row.orderId && !isError) {
                        row.orderStatus = order.orderStatus;
                        row.updateDatetime = order.updateDatetime;
                        row.updateUser = order.updateUser;
                        updateRows.push(row);
                      }
                    });
                  });
                }

                // 一覧部のレコードを更新
                if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
                // エラー表示
                if (errorRows.length > 0) {
                  // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                  this.$refs.confirmGrid.gridOptions.api.applyTransaction({ remove: updateRows });
                  this.$refs.bulkErrorGrid.open({ records: errorRows });
                  this.$dialog.notify.error(
                    `発注の${approval ? "承認" : "却下"}処理に失敗したデータが存在します。ご確認ください。`,
                    {
                      timeout: 2300,
                    }
                  );
                } else {
                  this.$dialog.notify.info(`選択された発注データを${approval ? "承認" : "却下"}しました`, {
                    timeout: 2300,
                  });
                  this.$refs.confirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("OrderSearch::onBtnApproval::onSubmit", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    async onDeliveryReportPrint() {
      try {
        this.loadingOn();
        var condition = {};
        if (this.isCafereoUser) {
          condition = {
            orders: this.selectionRows.map((row) => row.orderId),
          };
        } else {
          condition = {
            orders: this.selectionRows.map((row) => row.orderId),
          };
        }
        const response = await this.$store.dispatch("order/outDeliveryNote", condition);
        switch (response.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            var result = response.data.contents;
            window.open(`${result.exportFilePath}`, "_blank");
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
            var messages = [];
            var message = "";
            Object.keys(response.data?.header.messages).forEach(function (key) {
              messages.push(response.data?.header.messages[key]?.join("<br>"));
            });
            if (messages.length > 0) message = messages.join("<br>");
            if (message != "") {
              this.$dialog.warning({
                title: `納品書出力`,
                text: message,
                actions: ["OK"],
              });
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("OrderSearch::onDeliveryReportPrint", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onHyconClick() {
      this.$refs.hyconConfirmGrid.open({
        icon: "mdi-calendar-star",
        title: "ハイコン設定",
        columns: [
          { headerName: "PO", field: "poNumber", pinned: "left" },
          { headerName: "JANCODE", field: "janCode", pinned: "left" },
          { headerName: "タイトル", field: "title" },
          { headerName: "商品名", field: "productName" },
          { headerName: "出荷日", field: "shipDate", type: "dpDateColumn" },
          {
            headerName: "ハイコン希望日",
            field: "hycon",
            type: "dpEditableColumn",
            cellEditorFramework: "CustomDateCellEditor",
            valueFormatter: DateValueFormatter("YYYY/MM/DD"),
          },
          { headerName: "備考", field: "remarks" },
        ],
        records: JSON.parse(JSON.stringify(this.selectionRows)),
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            let errorRows = [];
            let isValid = true;
            records.forEach((row) => {
              var error = this.onValidHycon(row);
              if (error != true) {
                errorRows.push({
                  orderId: row.orderId,
                  errorMessage: error,
                });
                isValid = false;
              }
              updateModels.push({
                orderId: row.orderId,
                hiconPreferredDate: row.hycon,
                lastUpdateDatetime: row.updateDatetime,
              });
            });
            const updateModel = {
              orders: updateModels,
            };
            if (isValid) {
              const result = await this.$store.dispatch("amazon/hycon", updateModel);
              let error = result.data?.header;
              // 一覧更新レコード
              let updateRows = [];
              let errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        orderId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 一覧部の更新分のレコード変更
                  if (result.data.contents && result.data.contents.orders != null) {
                    this.selectionRows.forEach((row) => {
                      result.data.contents.orders.forEach((order) => {
                        let isError = errorRows.some((r) => r.orderId === row.orderId);
                        if (order.orderId == row.orderId && !isError) {
                          row.hycon = order.hycon;
                          row.updateDatetime = order.updateDatetime;
                          row.updateUser = order.updateUser;
                          updateRows.push(row);
                        }
                      });
                    });
                  }

                  // 一覧部のレコードを更新
                  if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
                  // エラー表示
                  if (errorRows.length > 0) {
                    // 確認ダイアログから更新完了分のデータを削除する
                    this.$refs.hyconConfirmGrid.gridApi.applyTransaction({ remove: updateRows });
                    this.$refs.bulkErrorGrid.open({ records: errorRows });
                    this.$dialog.notify.error(`ハイコンの設定処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`選択された受注にハイコンを設定しました`, { timeout: 2300 });
                    this.$refs.hyconConfirmGrid.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } else {
              this.$refs.bulkErrorGrid.open({ title: "ハイコン設定", records: errorRows });
              this.$dialog.notify.error(`入力エラーが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            }
          } catch (error) {
            console.error("OrderSearch::onHyconClick", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    setAmazonColumnVisible() {
      if (this.isCafereoUser && !this.isAchievementMode) {
        this.gridOptions.columnApi.setColumnsVisible(["poNumber", "hycon"], this.isAmazonStaff);
      }
    },
    calcTotalItem() {
      this.orderQuantityTotal = 0;
      this.orderPriceTotal = 0;
      this.orderPriceTaxTotal = 0;
      this.lowerPriceTotal = 0;
      this.grossPriceTotal = 0;
      this.grossRate = 0;
      this.shipQuantityTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.orderPriceTotal += row.unitPriceTotal ? row.unitPriceTotal : 0;
        this.orderPriceTaxTotal +=
          row.unitPriceTotal && row.consumptionTaxRate
            ? Math.round(row.unitPriceTotal * ((100 + row.consumptionTaxRate) / 100))
            : 0;
        if (this.isCafereoUser) {
          if (row.orderType != OrderTypes.PURCHASE && row.wholesaleRate == 0) {
            // NOP（委託でダミー受注の場合は何もしない。ドモルガンを使うと判定条件がわかりにくいのでelseで処理）
          } else {
            if (!this.isAchievementMode) {
              this.orderQuantityTotal += row.orderQuantity ? row.orderQuantity : 0;
            } else {
              this.shipQuantityTotal += row.shipQuantity ? row.shipQuantity : 0;
            }
            this.lowerPriceTotal += row.orderQuantity && row.unitPrice ? row.orderQuantity * row.unitPrice : 0;
            this.grossPriceTotal += row.grossProfitTotal ? row.grossProfitTotal : 0;
          }
        }
      }
      if (this.isCafereoUser) {
        const grossRate = Math.round((this.grossPriceTotal / this.lowerPriceTotal) * 1000) / 10;
        if (!isNaN(grossRate)) this.grossRate = grossRate;
      }
      this.orderPriceTaxTotal = Math.round(this.orderPriceTaxTotal);
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    orderedFromRules(value) {
      if (value == null || this.searchModel.orderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.orderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    orderedToRules(value) {
      if (value == null || this.searchModel.orderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.orderDateFrom)) return "発注日Fromより後の日付を指定してください";
      return true;
    },
    slipDateFromRules(value) {
      if (value == null || this.searchModel.slipDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.slipDateTo)) return "伝票日付Toより前の日付を指定してください";
      return true;
    },
    slipDateToRules(value) {
      if (value == null || this.searchModel.slipDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.slipDateFrom)) return "伝票日付Fromより後の日付を指定してください";
      return true;
    },
    releaseDateFromRules(value) {
      if (value == null || this.searchModel.releaseDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.releaseDateTo)) return "発売日Toより前の日付を指定してください";
      return true;
    },
    releaseDateToRules(value) {
      if (value == null || this.searchModel.releaseDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.releaseDateFrom)) return "発売日Fromより後の日付を指定してください";
      return true;
    },
    onValidHycon(row) {
      var ret = true;
      var messages = [];

      // ハイコン希望日
      this.setValidMessage(this.rules.required(row.hycon), "ハイコン希望日", messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
};
</script>
