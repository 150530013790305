<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12" sm="6" v-if="mode === 'order' && isCafereoUser">
                    <v-text-field
                      label="割り当て元受注ID"
                      :value="orderId"
                      readonly
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="mode === 'order' && isVendorUser">
                    <v-text-field
                      label="割り当て元発注ID"
                      :value="orderId"
                      readonly
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="mode === 'shop'">
                    <corporation-field
                      ref="corporate"
                      label="割り当て元法人名"
                      :rules="[rules.required]"
                      :group="CorporationTypeList.VENDOR"
                      v-model="corporateCode"
                      @selected="onCorporationChanged"
                      v-if="isCafereoUser"
                    ></corporation-field>
                    <v-text-field
                      label="割り当て元法人名"
                      :value="corporateName"
                      readonly
                      :rules="[rules.required]"
                      v-if="isVendorUser"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="mode === 'shop'">
                    <customer-field
                      ref="customer"
                      label="割り当て元取引先名"
                      v-model="customerCode"
                      :rules="[rules.required]"
                      :corporate="corporateCode"
                      disabled-no-data
                      @selected="onCustomerChanged"
                    ></customer-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      label="CSVファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isCsv]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <span class="" v-if="step == 2">登録予定件数：{{ csvRecords.length }}</span>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="csvRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">割振り確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p v-if="errorRows.length == 0 || mode !== 'order'">
                {{ csvRecords.length }}件中{{ importCount }}件の取り込みが完了しました。
              </p>
              <p v-if="errorRows.length > 0 && mode !== 'order'">取込エラーがあります。確認してください。</p>
              <p v-if="errorRows.length > 0 && mode === 'order'">エラー行があったため取込み出来ませんでした。</p>
              <p v-if="isSkip">スキップしたデータがあります。csvを確認してください。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="一括割当取込"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import FileUtils from "../../utils/FileUtils";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import CorporationField from "../common/fields/CorporationField.vue";
import CustomerField from "../common/fields/CustomerField.vue";
import CorporationTypes from "../../consts/CorporationTypes";
import { corpList as CorpList } from "../../consts/CorpList";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ForecastAssign",
  props: ["mode", "orderId", "lastUpdateDatetime", "entryCorporationType"],
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
    CorporationField,
    CustomerField,
  },
  data: function () {
    return {
      step: 1,
      corporateCode: null,
      corporateName: null,
      customerCode: null,
      yodobashiImport: false,
      isSkip: false,
      file: null,
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: false,
          filter: false,
          editable: false,
        },
        columnDefs: [
          {
            field: "jancode",
            headerName: "JANCODE",
            width: 200,
            editable: () => this.mode === "shop",
            cellStyle: () => (this.mode === "shop" ? {} : { color: "gray" }),
          },
          {
            field: "shopcode",
            headerName: "店舗CD",
            width: 180,
          },
          {
            field: "shipcode",
            headerName: "配送先CD",
            width: 180,
          },
          {
            field: "quantity",
            type: "dpNumericColumn",
            headerName: "数量",
            width: 120,
          },
          {
            field: "partnerPurchaseNo",
            headerName: "相手先発注番号",
            width: 120,
          },
          {
            field: "partnerDetailNo",
            headerName: "相手先明細番号",
            width: 120,
          },
        ],
        rowSelection: false,
        suppressRowClickSelection: true,
        singleClickEdit: true,
        pagination: false,
        localeText: AG_GRID_LOCALE_JA,
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpFullDateColumn: FullDateColumn,
          dpDateColumn: DateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
      },
      csvRecords: [],
      importNumber: null,
      validImportForm: null,
      yodobashiImportFileFomat: {
        headerCnt: 1,
        details: [
          { fieldName: "発注伝票番号" },
          { fieldName: "発注伝票明細番号" },
          { fieldName: "発注伝票日付" },
          { fieldName: "発注伝票作成日付" },
          { fieldName: "発注伝票作成時刻" },
          { fieldName: "受注先名" },
          { fieldName: "受注先部署名" },
          { fieldName: "受注先担当者名" },
          { fieldName: "受注先電話番号" },
          { fieldName: "受注先 FAX" },
          { fieldName: "受注先 Email" },
          { fieldName: "仕入先コード" },
          { fieldName: "仕入先名" },
          { fieldName: "発注先コード" },
          { fieldName: "発注先名" },
          { fieldName: "納品先コード" },
          { fieldName: "納品先名" },
          { fieldName: "直送先名" },
          { fieldName: "直送先電話番号" },
          { fieldName: "直送先住所1" },
          { fieldName: "直送先住所2" },
          { fieldName: "直送先住所3" },
          { fieldName: "直送先住所4" },
          { fieldName: "直送先郵便番号" },
          { fieldName: "直送伝票番号" },
          { fieldName: "品目コード" },
          { fieldName: "品目名" },
          { fieldName: "明細区分1" },
          { fieldName: "明細区分2" },
          { fieldName: "明細区分3" },
          { fieldName: "単価" },
          { fieldName: "配送場所コード" },
          { fieldName: "受注数量" },
          { fieldName: "納入期日" },
          { fieldName: "納入時刻" },
          { fieldName: "仕入先部門" },
          { fieldName: "受注売場" },
        ],
        quotes: 1,
        conversionFlg: true,
      },
      loftImportFileFomat: {
        headerCnt: 0,
        details: [
          { fieldName: "発注企業コード" },
          { fieldName: "発注企業名称" },
          { fieldName: "発注店舗コード" },
          { fieldName: "発注取引先名称" },
          { fieldName: "受注企業コード" },
          { fieldName: "受注企業名称" },
          { fieldName: "受注拠点コード" },
          { fieldName: "MDグループコード" },
          { fieldName: "発注区分、" },
          { fieldName: "発注年月日" },
          { fieldName: "発注時間" },
          { fieldName: "店舗納品日" },
          { fieldName: "便区分" },
          { fieldName: "出荷予定日" },
          { fieldName: "受注確認年月日" },
          { fieldName: "受注確認時間" },
          { fieldName: "伝票番号" },
          { fieldName: "仕入形態" },
          { fieldName: "品別番号" },
          { fieldName: "値札印字番号" },
          { fieldName: "税区分" },
          { fieldName: "納品場所コード" },
          { fieldName: "納品場所名称" },
          { fieldName: "経由区分" },
          { fieldName: "店出場所コード" },
          { fieldName: "売場名称" },
          { fieldName: "担当者名称" },
          { fieldName: "決裁者名称" },
          { fieldName: "内線番号" },
          { fieldName: "備考" },
          { fieldName: "伝票明細行番号" },
          { fieldName: "ＪＡＮコード" },
          { fieldName: "取引先品番" },
          { fieldName: "商品名称漢字" },
          { fieldName: "商品名称カナ" },
          { fieldName: "発注数量" },
          { fieldName: "発注単位" },
          { fieldName: "発注ロット" },
          { fieldName: "発注原単価" },
          { fieldName: "発注原価金額" },
          { fieldName: "発注売単価" },
          { fieldName: "発注売価金額" },
          { fieldName: "出荷数量" },
          { fieldName: "出荷単位" },
          { fieldName: "出荷原単価" },
          { fieldName: "出荷原価金額" },
          { fieldName: "出荷売単価" },
          { fieldName: "出荷売価金額" },
          { fieldName: "出荷時必須訂正理由ｺｰﾄﾞ" },
          { fieldName: "出荷時詳細訂正理由文言" },
          { fieldName: "パターン番号" },
        ],
        quotes: 1,
        conversionFlg: true,
      },
      sofmapImportFileFomat: {
        headerCnt: 0,
        details: [
          { fieldName: "データ種別" },
          { fieldName: "法人コード" },
          { fieldName: "発注場所コード" },
          { fieldName: "納品場所コード" },
          { fieldName: "フロアコード" },
          { fieldName: "発注年月日" },
          { fieldName: "有効期限年月日" },
          { fieldName: "取引先コード" },
          { fieldName: "発注No" },
          { fieldName: "発注行No" },
          { fieldName: "ＪＡＮコード" },
          { fieldName: "型番・商品名" },
          { fieldName: "色・規格" },
          { fieldName: "発注数量" },
          { fieldName: "発注時単価" },
          { fieldName: "定価" },
          { fieldName: "出荷数量" },
          { fieldName: "出荷時単価" },
          { fieldName: "お客様着日" },
          { fieldName: "弊社指定納品日" },
          { fieldName: "法人区分" },
          { fieldName: "伝票No" },
          { fieldName: "入荷予定区分／納期回答区分" },
          { fieldName: "コメント欄" },
          { fieldName: "納品予定日" },
          { fieldName: "配送業者コード" },
          { fieldName: "送り状番号" },
          { fieldName: "セッティング／引取り区分" },
        ],
        quotes: 1,
        conversionFlg: true,
      },
      villageImportFileFomat: {
        headerCnt: 1,
        details: [
          { fieldName: "データ処理番号" },
          { fieldName: "情報区分コード" },
          { fieldName: "データ作成日付" },
          { fieldName: "データ作成時刻" },
          { fieldName: "発注者コード" },
          { fieldName: "受注者コード" },
          { fieldName: "訂正区分" },
          { fieldName: "更新回数" },
          { fieldName: "最新更新日付" },
          { fieldName: "発注部門コード" },
          { fieldName: "発注部門名称" },
          { fieldName: "請求部門コード" },
          { fieldName: "請求部門名称" },
          { fieldName: "受注部門コード" },
          { fieldName: "受注部門名称" },
          { fieldName: "発注担当者コード" },
          { fieldName: "発注担当者名称" },
          { fieldName: "一括納入区分" },
          { fieldName: "分納区分" },
          { fieldName: "直納区分" },
          { fieldName: "納入指定日" },
          { fieldName: "出荷指定日" },
          { fieldName: "発注番号" },
          { fieldName: "発注伝票№" },
          { fieldName: "発注伝票行№" },
          { fieldName: "発注日付" },
          { fieldName: "ＪＡＮコード" },
          { fieldName: "商品略称" },
          { fieldName: "商品正式名称" },
          { fieldName: "メーカーコード" },
          { fieldName: "メーカー名称" },
          { fieldName: "型番" },
          { fieldName: "色柄" },
          { fieldName: "売単価" },
          { fieldName: "仕入単価" },
          { fieldName: "注文数量" },
          { fieldName: "明細金額" },
          { fieldName: "納入先コード" },
          { fieldName: "納入先郵便番号" },
          { fieldName: "納入先名" },
          { fieldName: "納入先担当者" },
          { fieldName: "納入先住所" },
          { fieldName: "納入先電話番号" },
          { fieldName: "希望配送日" },
          { fieldName: "希望お届け時間帯" },
          { fieldName: "荷主担当者" },
          { fieldName: "荷主郵便番号" },
          { fieldName: "荷主名称" },
          { fieldName: "荷主住所" },
          { fieldName: "荷主電話番号" },
          { fieldName: "発注区分" },
          { fieldName: "元発注番号" },
          { fieldName: "元発注伝票№" },
          { fieldName: "元発注伝票行№" },
          { fieldName: "自由使用欄" },
          { fieldName: "店舗卸額" },
          { fieldName: "値引額" },
          { fieldName: "本部卸額" },
        ],
        quotes: 1,
        conversionFlg: true,
      },
      importFileFomat: {
        headerCnt: 1,
        details: [
          { fieldName: "JANCODE" },
          { fieldName: "取引先CD" },
          { fieldName: "配送先CD" },
          { fieldName: "受注数" },
          { fieldName: "相手先発注番号" },
          { fieldName: "相手先明細番号" },
        ],
      },
      importHelper: null,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isRate: Validation.isRate,
        isMinNumber: Validation.isMinNumber,
        isCsv: Validation.isCsv,
        isCode: Validation.isCode,
        isDecimal: Validation.isDecimal,
      },
      importCount: 0,
      errorColmuns: [
        { headerName: "行番号", field: "rowNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      CorporationTypeList: CorporationTypes,
      loginUserCorporateCd: "",
      conversionFlg: false,
      deliveryConversionFlg: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.yodobashiImport = false;
          this.isSkip = false;
          this.csvRecords = [];
          this.loginUserCorporateCd = this.$store.getters["security/loggedInUser"].corporateCode;
          var csvLines = [];
          if (CorpList.YODOBASHI == this.loginUserCorporateCd) {
            this.yodobashiImport = true;
          }

          if (this.importHelper.quotes === 1) {
            csvLines = await FileUtils.readAsCsvSpecQuote(this.file, true);
          } else {
            csvLines = await FileUtils.readAsCsv(this.file, true);
          }

          this.conversionFlg = this.importHelper.conversionFlg;
          var skipIndex = CorpList.LOFT == this.loginUserCorporateCd ? -1 : 0;
          // 店舗指定とロジック統一 2023/11/14課題
          //this.deliveryConversionFlg = CorpList.SOFMAP == this.loginUserCorporateCd ? true : false;
          let index = 0;
          for (let line of csvLines) {
            if (index > skipIndex && line.length > 1) {
              console.log(line.length + ":::" + this.importHelper.details.length);
              if (line.length !== this.importHelper.details.length) {
                this.$dialog.notify.error(`ファイルフォーマットエラー（${index}行目）`, { timeout: 2300 });
                return;
              }
              var csvRecord = this.convertFormatData(line);
              csvRecord.rowNumber = index;
              this.csvRecords.push(csvRecord);
            }
            index++;
          }
          if (this.validateRecords(this.csvRecords)) {
            this.step++;
          }
        } catch (error) {
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
        } finally {
          await this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      this.isSkip = false;
      var isValid = true;
      this.csvRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          // 最大件数チェック
          const functionName = this.isCafereoUser ? "C020302" : "V020302";
          const validDataSizeRet = await RequestUtils.validDataSize(
            functionName,
            requestRecords.length,
            (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            }
          );
          if (!validDataSizeRet) {
            return;
          }
          this.errorRows = [];
          this.importNumber = null;
          // 150件毎にスライス（タイムアウトがあったため50件毎に修正20231003
          const sliceByNumber = (array, number) => {
            const length = Math.ceil(array.length / number);
            return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
          };
          let requestRecordsSplit = sliceByNumber(requestRecords, 50);
          let skipList = [];
          this.importCount = 0;
          for (let i = 0; i < requestRecordsSplit.length; i++) {
            var param = {
              assingMethod: this.mode === "order" ? 1 : this.yodobashiImport ? 3 : 2,
              orderId: this.stringFormat(this.orderId),
              customerCode: this.stringFormat(this.customerCode),
              orders: requestRecordsSplit[i],
              lastUpdateDateTime: this.lastUpdateDatetime,
              conversionFlg: this.conversionFlg,
              deliveryConversionFlg: this.deliveryConversionFlg,
              importNumber: this.importNumber,
              skipList: skipList,
              isLast: i == requestRecordsSplit.length - 1,
            };
            const response = await this.$store.dispatch("forcast/importAssignment", param);
            let error = response.data?.header;
            let errorCount = 0;
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
                this.importNumber = response.data.contents.importNumber;
                this.importCount += requestRecordsSplit[i].length;
                if (response.data?.contents?.exportFilePath) {
                  this.isSkip = true;
                  window.open(response.data?.contents?.exportFilePath, "_blank");
                }
                if (response.data?.contents?.skipList) {
                  skipList = response.data?.contents?.skipList;
                }
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    this.errorRows.push({
                      rowNumber: key,
                      errorMessage: error.messages[key],
                    });
                    errorCount = errorCount + 1;
                  });
                }
                if (response.data?.contents?.exportFilePath) {
                  this.isSkip = true;
                  window.open(response.data?.contents?.exportFilePath, "_blank");
                }
                this.importNumber = response.data.contents.importNumber;
                this.importCount += requestRecordsSplit[i].length - errorCount;
                if (response.data?.contents?.skipList) {
                  skipList = response.data?.contents?.skipList;
                }
                break;
              default:
                this.redirectError();
                break;
            }
          }

          this.importCount = this.importCount - skipList.length;
          if (this.errorRows.length > 0) {
            this.$refs.importErrorGrid.open({ records: this.errorRows });
          } else {
            this.$refs.importErrorGrid.close();
            var messsage = this.isCafereoUser ? "受注" : "発注";
            this.$dialog.notify.info(`${messsage}の割振りが完了しました`, { timeout: 2300 });
          }
          this.step++;
        } catch (error) {
          console.error("OrderImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      if (this.isVendorUser) {
        this.corporateCode = this.$store.getters["security/loggedInUser"].corporateCode;
        this.corporateName = this.$store.getters["security/loggedInUser"].corporateName;
      } else {
        this.corporateCode = null;
      }
      this.customerCode = null;
      this.file = null;
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      console.log(row);
      // JANCODE
      this.setValidMessage(this.rules.required(row.jancode), "JANCODE", messages);
      this.setValidMessage(this.rules.isNumber(row.jancode), "JANCODE", messages);
      this.setValidMessage(this.rules.maxLength(13)(row.jancode), "JANCODE", messages);
      // 店舗CD
      this.setValidMessage(this.rules.required(row.shopcode), "店舗CD", messages);
      this.setValidMessage(this.rules.isCode(row.shopcode), "店舗CD", messages);
      this.setValidMessage(this.rules.maxLength(12)(row.shopcode), "店舗CD", messages);
      // 配送先CD
      if (!this.conversionFlg) {
        this.setValidMessage(this.rules.required(row.shipcode), "配送先CD", messages);
        this.setValidMessage(this.rules.isNumber(row.shipcode), "配送先CD", messages);
        this.setValidMessage(this.rules.maxLength(12)(row.shipcode), "配送先CD", messages);
      }
      // 数量
      this.setValidMessage(this.rules.required(row.quantity), "数量", messages);
      this.setValidMessage(this.rules.isDecimal(row.quantity), "数量", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.quantity), "数量", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.quantity), "数量", messages);

      this.setValidMessage(this.rules.maxLength(64)(row.partnerPurchaseNo), "相手先発注番号", messages);
      this.setValidMessage(this.rules.isCode(row.partnerDetailNo), "相手先明細番号", messages);
      this.setValidMessage(this.rules.maxLength(6)(row.partnerDetailNo), "相手先明細番号", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    requestFormat(row) {
      return {
        janCode: this.stringFormat(row.jancode),
        customerCode: this.stringFormat(row.shopcode),
        deliveryCode: this.stringFormat(row.shipcode),
        orderQuantity: this.numberFormat(row.quantity),
        partnerPurchaseNo: this.stringFormat(row.partnerPurchaseNo),
        partnerDetailNo: this.numberFormat(row.partnerDetailNo),
        rowNumber: this.numberFormat(row.rowNumber),
        yodobashiImportType: this.numberFormat(row.yodobashiImportType),
        deliveryDeadline: this.stringFormat(row.deliveryDeadline),
        slipRemarks: this.stringFormat(row.slipRemarks),
      };
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onCorporationChanged(value) {
      this.corporateCode = value;
    },
    onCustomerChanged(value) {
      this.customerCode = value;
    },
    convertFormatData(line) {
      // ログインユーザが所属する法人によってマッピングを修正
      var data = {
        rowNumber: null,
        jancode: "",
        shopcode: "",
        shipcode: "",
        quantity: null,
        partnerPurchaseNo: "",
        partnerDetailNo: null,
        yodobashiImportType: null,
      };
      if (CorpList.YODOBASHI == this.loginUserCorporateCd) {
        data.jancode = line[25];
        data.quantity = line[32];
        data.shopcode = line[31];
        data.partnerPurchaseNo = line[0];
        data.partnerDetailNo = line[1];
        data.deliveryDeadline = line[33];
        data.slipRemarks = line[27];
        if (line[27] == "特注") {
          data.yodobashiImportType = 1;
        } else {
          let now = moment().add(7, "days");
          let checkDate = moment(line[33], "YYYYMMDD");
          if (now.isSameOrAfter(checkDate, "days")) {
            data.yodobashiImportType = 2;
          } else {
            data.yodobashiImportType = 3;
          }
        }
      } else if (CorpList.LOFT == this.loginUserCorporateCd) {
        data.jancode = line[31];
        data.quantity = line[35];
        data.shopcode = line[2];
        data.partnerPurchaseNo = line[16];
      } else if (CorpList.SOFMAP == this.loginUserCorporateCd) {
        data.jancode = line[10];
        data.quantity = line[13];
        data.shopcode = line[2];
        data.shipcode = line[3];
        data.partnerPurchaseNo = line[8];
        data.partnerDetailNo = line[9];
      } else if (CorpList.VILLAGEVANGUARD == this.loginUserCorporateCd) {
        data.jancode = line[26];
        data.quantity = line[35];
        data.shopcode = line[9];
        data.partnerPurchaseNo = line[23];
      } else {
        data.jancode = line[0];
        data.shopcode = line[1];
        data.shipcode = line[2];
        data.quantity = line[3];
        data.partnerPurchaseNo = line[4];
        data.partnerDetailNo = line[5];
      }
      return data;
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.isSkip = false;
      this.step--;
    },
  },
  beforeMount() {
    if (this.isVendorUser) {
      this.corporateCode = this.$store.getters["security/loggedInUser"].corporateCode;
      this.corporateName = this.$store.getters["security/loggedInUser"].corporateName;
    }
    // ファイルフォーマット振り分け
    if (CorpList.YODOBASHI == this.corporateCode) {
      this.importHelper = FileUtils.importHelper(this.yodobashiImportFileFomat);
    } else if (CorpList.LOFT == this.corporateCode) {
      this.importHelper = FileUtils.importHelper(this.loftImportFileFomat);
    } else if (CorpList.SOFMAP == this.corporateCode) {
      this.importHelper = FileUtils.importHelper(this.sofmapImportFileFomat);
    } else if (CorpList.VILLAGEVANGUARD == this.corporateCode) {
      this.importHelper = FileUtils.importHelper(this.villageImportFileFomat);
    } else {
      this.importHelper = FileUtils.importHelper(this.importFileFomat);
    }
    console.log(this.importHelper);
  },
};
</script>
